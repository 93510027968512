<template>
  <RecordViewWrapper>
    <Main>
      <div v-if="userPartnerInfo">
        <sdPageHeader :title="i18n.t('partners.partners')">
          <template #buttons>
            <div class="partners__inner">
              <div
                :class="`partners__referral-card partners__level level_${userPartnerInfo.level}`"
                style="height: 100%"
              >
                <div>
                  <p>{{ i18n.t('partners.level') }}</p>
                  <!--<h3 class="partners__level-status" @click="changeVisibleLevelInfoModal">
                    {{ i18n.t('partners.' + userPartnerInfo.level) }}
                  </h3>-->
                  <h3 class="partners__level-status">
                    <a href="https://mthub.io/affiliates" target="_blank">
                      {{ `${i18n.t('partners.' + userPartnerInfo.level)} →` }}
                    </a>
                  </h3>
                </div>
              </div>
              <!--<div class="partners__referral-card" style="height: 100%">
                <div>
                  <p>{{ i18n.t('partners.refferalCode') }}</p>
                  <h3 @click="copyCode">
                    {{ userPartnerInfo.code }}
                  </h3>
                </div>
                <sdButton size="default" class="partners__copy copy" :outlined="true" type="light" @click="copyCode">
                  <sdFeatherIcons type="copy" />
                </sdButton>
              </div>-->
              <div class="partners__referral-card">
                <div>
                  <p>{{ i18n.t('start.partnerLink') }}</p>
                  <h3 @click="copyAddress">
                    {{ `${refferalLink[i18n.locale]}?ref=${userPartnerInfo.code}` }}
                  </h3>
                </div>
                <sdButton size="default" class="partners__copy copy" :outlined="true" type="light" @click="copyAddress">
                  <sdFeatherIcons type="copy" />
                </sdButton>
              </div>
            </div>
          </template>
        </sdPageHeader>
        <!--<sdCards headless>
          <sdHeading as="h5">{{ i18n.t('partners.info.title') }} </sdHeading>
          <p class="info__description">{{ i18n.t('partners.info.description') }}</p>
          <a-collapse v-model:activeKey="activeKey">
            <a-collapse-panel key="1" :header="i18n.t('partners.info.workTitle')">
              <div v-html="i18n.t('partners.info.workText')"></div>
            </a-collapse-panel>
            <a-collapse-panel key="2" :header="i18n.t('partners.info.benefitsTitle')" :disabled="false">
              <div v-html="i18n.t('partners.info.benefitsText')"></div>
            </a-collapse-panel>
          </a-collapse>
        </sdCards>-->
        <a-row :gutter="20" v-if="stats.promo">
          <a-col :lg="16" :md="16" :xs="24">
            <sdCards class="start__header partners-card">
              <sdHeading as="h5" style="margin-right: auto">{{ i18n.t('partners.stats.promo.title') }}</sdHeading>
              <div class="promo-block">
                <div class="promo-block__inner">
                  <div class="promo-block__title">{{ i18n.t('partners.stats.promo.time') }}:</div>
                  <div class="promo-block__value">{{ stats.promo.tickets.time }}</div>
                </div>
                <div class="promo-block__inner">
                  <div class="promo-block__title">{{ i18n.t('partners.stats.promo.description') }}:</div>
                  <div class="promo-block__value">{{ stats.promo.tickets.description }}</div>
                  <div class="promo-block__more">
                    <a :href="`${stats.promo.tickets.link}`" target="_blank">
                      {{ i18n.t('partners.stats.promo.more') }}
                    </a>
                  </div>
                </div>
                <div class="promo-block__inner">
                  <div class="promo-block__title">{{ i18n.t('partners.stats.promo.count') }}:</div>
                  <div class="promo-block__value">{{ stats.promo.tickets.count }}</div>
                </div>
              </div>
            </sdCards>
          </a-col>
        </a-row>
        <a-row :gutter="20" v-if="stats.points">
          <a-col :lg="16" :md="16" :xs="24">
            <sdCards class="start__header partners-card">
              <sdHeading as="h5" style="margin-right: auto">{{ i18n.t('partners.stats.points.title') }}</sdHeading>
              <div class="points-stat-block">
                <div class="points-stat-block__left">
                  <div class="points-stat-block__inner">
                    <div class="points-stat-block__title">{{ i18n.t('partners.stats.points.start') }}:</div>
                    <div class="points-stat-block__value">{{ stats.points.start }}</div>
                  </div>
                  <div class="points-stat-block__inner">
                    <div class="points-stat-block__title">{{ i18n.t('partners.stats.points.filled_users') }}:</div>
                    <div class="points-stat-block__value">{{ stats.points.filled_users }}</div>
                  </div>
                  <div class="points-stat-block__inner">
                    <div class="points-stat-block__title">{{ i18n.t('partners.stats.points.investor_sum') }}:</div>
                    <div class="points-stat-block__value">{{ stats.points.investor_sum }}</div>
                  </div>
                  <div class="points-stat-block__inner">
                    <div class="points-stat-block__title">{{ i18n.t('partners.stats.points.token_sum') }}:</div>
                    <div class="points-stat-block__value">{{ stats.points.token_sum }}</div>
                  </div>
                </div>
                <div class="points-stat-block__right">
                  <div class="points-stat-block__inner" v-if="stats.points.one_investor_vol !== undefined">
                    <div class="points-stat-block__title">{{ i18n.t('partners.stats.points.one_investor_vol') }}:</div>
                    <div class="points-stat-block__value">{{ stats.points.one_investor_vol }}</div>
                  </div>
                  <div class="points-stat-block__inner" v-if="stats.points.two_investor_vol !== undefined">
                    <div class="points-stat-block__title">{{ i18n.t('partners.stats.points.two_investor_vol') }}:</div>
                    <div class="points-stat-block__value">{{ stats.points.two_investor_vol }}</div>
                  </div>
                  <div class="points-stat-block__inner" v-if="stats.points.three_investor_vol !== undefined">
                    <div class="points-stat-block__title">
                      {{ i18n.t('partners.stats.points.three_investor_vol') }}:
                    </div>
                    <div class="points-stat-block__value">{{ stats.points.three_investor_vol }}</div>
                  </div>
                  <div class="points-stat-block__inner" v-if="stats.points.four_investor_vol !== undefined">
                    <div class="points-stat-block__title">{{ i18n.t('partners.stats.points.four_investor_vol') }}:</div>
                    <div class="points-stat-block__value">{{ stats.points.four_investor_vol }}</div>
                  </div>
                  <div class="points-stat-block__inner" v-if="stats.points.five_investor_vol !== undefined">
                    <div class="points-stat-block__title">{{ i18n.t('partners.stats.points.five_investor_vol') }}:</div>
                    <div class="points-stat-block__value">{{ stats.points.five_investor_vol }}</div>
                  </div>
                </div>
              </div>
            </sdCards>
          </a-col>
          <a-col :lg="8" :md="8" :xs="24">
            <sdCards class="start__header partners-card">
              <sdHeading as="h5" style="margin-right: auto">{{ i18n.t('partners.stats.points.percent') }}</sdHeading>
              <div class="points-stat-block-percent">{{ stats.points.percent }}%</div>
            </sdCards>
          </a-col>
        </a-row>
        <a-row :gutter="20" v-if="stats.profit && stats.referrals_count && stats.partners_count">
          <a-col :lg="8" :md="12" :xs="24">
            <sdCards class="start__header partners-card">
              <sdHeading as="h5" style="margin-right: auto">{{ i18n.t('partners.stats.profit') }}</sdHeading>
              <div class="stat-block">
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.day') }}:</p>
                  <p class="stat-block__value">{{ stats.profit.day }} USDT</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.week') }}:</p>
                  <p class="stat-block__value">{{ stats.profit.week }} USDT</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.month') }}:</p>
                  <p class="stat-block__value">{{ stats.profit.month }} USDT</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.all') }}:</p>
                  <p class="stat-block__value">{{ stats.profit.all }} USDT</p>
                </div>
              </div>
            </sdCards>
          </a-col>
          <a-col :lg="8" :md="12" :xs="24">
            <sdCards class="start__header partners-card">
              <sdHeading as="h5" style="margin-right: auto">{{ i18n.t('partners.stats.partners_mlm') }}</sdHeading>
              <div class="stat-block">
                <div class="stat-block__inner" v-if="stats.partners_count.one !== undefined">
                  <p class="stat-block__title">1 {{ i18n.t('partners.stats.level') }}:</p>
                  <p class="stat-block__value">{{ stats.partners_count.one }}</p>
                </div>
                <div class="stat-block__inner" v-if="stats.partners_count.two !== undefined">
                  <p class="stat-block__title">2 {{ i18n.t('partners.stats.level') }}:</p>
                  <p class="stat-block__value">{{ stats.partners_count.two }}</p>
                </div>
                <div class="stat-block__inner" v-if="stats.partners_count.three !== undefined">
                  <p class="stat-block__title">3 {{ i18n.t('partners.stats.level') }}:</p>
                  <p class="stat-block__value">{{ stats.partners_count.three }}</p>
                </div>
                <div class="stat-block__inner" v-if="stats.partners_count.four !== undefined">
                  <p class="stat-block__title">4 {{ i18n.t('partners.stats.level') }}:</p>
                  <p class="stat-block__value">{{ stats.partners_count.four }}</p>
                </div>
                <div class="stat-block__inner" v-if="stats.partners_count.five !== undefined">
                  <p class="stat-block__title">5 {{ i18n.t('partners.stats.level') }}:</p>
                  <p class="stat-block__value">{{ stats.partners_count.five }}</p>
                </div>
              </div>
            </sdCards>
          </a-col>
          <a-col :lg="8" :md="12" :xs="24">
            <sdCards headless class="start__header partners-card">
              <div class="bill">
                <a-statistic class="bill__amount _option" :value="stats.options_account_amount" :precision="2">
                  <template #title>
                    <p class="bill__title">
                      <sdFeatherIcons class="bill__icon" type="trending-up" size="18" />
                      {{ i18n.t(`accounts.optionName`) }}
                    </p>
                  </template>
                  <template #suffix>
                    <p class="bill__currency">USDT</p>
                  </template>
                </a-statistic>
              </div>
            </sdCards>
          </a-col>
          <a-col :lg="8" :md="12" :xs="24" v-if="stats.referrals_count.one">
            <sdCards class="start__header partners-card">
              <sdHeading as="h5" style="margin-right: auto">{{ i18n.t('partners.stats.referrals_one') }}</sdHeading>
              <div class="stat-block">
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.day') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.one.day }}</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.week') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.one.week }}</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.month') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.one.month }}</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.all') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.one.all }}</p>
                </div>
              </div>
            </sdCards>
          </a-col>
          <a-col :lg="8" :md="12" :xs="24" v-if="stats.referrals_count.two">
            <sdCards class="start__header partners-card">
              <sdHeading as="h5" style="margin-right: auto">{{ i18n.t('partners.stats.referrals_two') }}</sdHeading>
              <div class="stat-block">
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.day') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.two.day }}</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.week') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.two.week }}</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.month') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.two.month }}</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.all') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.two.all }}</p>
                </div>
              </div>
            </sdCards>
          </a-col>
          <a-col :lg="8" :md="12" :xs="24" v-if="stats.referrals_count.three">
            <sdCards class="start__header partners-card">
              <sdHeading as="h5" style="margin-right: auto">{{ i18n.t('partners.stats.referrals_three') }}</sdHeading>
              <div class="stat-block">
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.day') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.three.day }}</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.week') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.three.week }}</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.month') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.three.month }}</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.all') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.three.all }}</p>
                </div>
              </div>
            </sdCards>
          </a-col>
          <a-col :lg="8" :md="12" :xs="24" v-if="stats.referrals_count.four">
            <sdCards class="start__header partners-card">
              <sdHeading as="h5" style="margin-right: auto">{{ i18n.t('partners.stats.referrals_four') }}</sdHeading>
              <div class="stat-block">
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.day') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.four.day }}</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.week') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.four.week }}</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.month') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.four.month }}</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.all') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.four.all }}</p>
                </div>
              </div>
            </sdCards>
          </a-col>
          <a-col :lg="8" :md="12" :xs="24" v-if="stats.referrals_count.five">
            <sdCards class="start__header partners-card">
              <sdHeading as="h5" style="margin-right: auto">{{ i18n.t('partners.stats.referrals_five') }}</sdHeading>
              <div class="stat-block">
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.day') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.five.day }}</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.week') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.five.week }}</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.month') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.five.month }}</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">{{ i18n.t('partners.stats.all') }}:</p>
                  <p class="stat-block__value">{{ stats.referrals_count.five.all }}</p>
                </div>
              </div>
            </sdCards>
          </a-col>
          <a-col :lg="8" :md="12" :xs="24" v-if="stats.invested_volume">
            <sdCards class="start__header partners-card">
              <sdHeading as="h5" style="margin-right: auto">{{ i18n.t('partners.stats.invested_volume') }}</sdHeading>
              <div class="stat-block">
                <div class="stat-block__inner">
                  <p class="stat-block__title">1 lvl:</p>
                  <p class="stat-block__value">{{ stats.invested_volume.one }} USDT</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">2 lvl:</p>
                  <p class="stat-block__value">{{ stats.invested_volume.two }} USDT</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">3 lvl:</p>
                  <p class="stat-block__value">{{ stats.invested_volume.three }} USDT</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">4 lvl:</p>
                  <p class="stat-block__value">{{ stats.invested_volume.four }} USDT</p>
                </div>
                <div class="stat-block__inner">
                  <p class="stat-block__title">5 lvl:</p>
                  <p class="stat-block__value">{{ stats.invested_volume.five }} USDT</p>
                </div>
              </div>
            </sdCards>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col class="w-100" :md="24">
            <sdPageHeader :title="i18n.t('partners.table')">
              <template v-slot:buttons>
                <div style="display: flex; align-items: center">
                  <a-input-search
                    v-model:value="searchingName"
                    style="margin-right: 20px"
                    :placeholder="i18n.t('partners.search')"
                    v-if="dataSource[0] || searchingName"
                    @input="expandRows"
                  />
                  <!--<router-link :to="{ name: 'partner-tree' }" v-if="dataSource[0] || searchingName">
                    <sdButton type="primary">
                      {{ i18n.t('partners.tree') }}
                    </sdButton>
                  </router-link>-->
                </div>
              </template>
            </sdPageHeader>
            <sdCards headless>
              <TableWrapper class="table-data-view table-responsive">
                <a-table
                  :dataSource="dataSource"
                  class="partner-table"
                  :columns="columns"
                  :expandRowByClick="true"
                  :pagination="false"
                  :expandedRowKeys="expandedRows"
                  @expandedRowsChange="onExpand"
                  ref="tableRef"
                >
                  <template #expandIcon="props">
                    <sdFeatherIcons
                      style="margin-right: 3px"
                      type="chevron-down"
                      v-if="!props.expanded && props.record.has_referals"
                    />
                    <sdFeatherIcons type="chevron-up" style="margin-right: 5px" v-else-if="props.record.has_referals" />
                    <sdFeatherIcons type="minus" style="margin-right: 5px" size="10" v-else />
                  </template>
                  <template #crated_at="props">
                    {{ props.record.created_at ? i18n.d(props.record.created_at, 'short') : '-' }}
                  </template>
                  <template #refferalCode="{ record }">
                    <p class="searched">
                      <span>{{ record.referal_code }}</span>
                    </p>
                  </template>
                  <template #namesec="{ record }">
                    <p class="searched">
                      <span> {{ record.name_surname.trim() == '' ? '-' : record.name_surname }} </span>
                    </p>
                  </template>
                  <template #email="{ record }">
                    <p class="searched">
                      <span>{{ record.email }}</span>
                    </p>
                  </template>
                  <template #phone="{ record }">
                    <p class="searched">
                      <span>{{ record.phone ?? '-' }}</span>
                    </p>
                  </template>
                  <template #sum_commission="{ record }">
                    <p class="searched">
                      <span>{{ record.sum_commission.amount }} {{ record.sum_commission.currency }}</span>
                    </p>
                  </template>
                </a-table>
              </TableWrapper>
            </sdCards>
          </a-col>
        </a-row>
      </div>
      <div class="loading-data" v-else>
        <a-spin size="large"></a-spin>
      </div>
      <!--<PartnersStatusInfo :visible="visibleLevelInfoModal" @changeModal="changeVisibleLevelInfoModal" />-->
    </Main>
  </RecordViewWrapper>
</template>
<script>
import { RecordViewWrapper } from './style';
import { Main, TableWrapper } from '../styled';
import { useStore } from 'vuex';
import { computed, onMounted, ref, defineAsyncComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { message } from 'ant-design-vue';
const PartnersStatusInfo = defineAsyncComponent(() => import('./overview/PartnersStatusInfo.vue'));
const beWithUs = defineAsyncComponent(() => import('./overview/BeWithUs'));

const ViewPage = {
  name: 'ViewPage',
  components: {
    RecordViewWrapper,
    Main,
    TableWrapper,
    PartnersStatusInfo,
    beWithUs,
  },
  setup() {
    const { state, dispatch } = useStore();
    const i18n = reactive(useI18n());

    const isModalActive = ref(false);
    const visibleLevelInfoModal = ref(false);
    const activeKey = ref();

    const partnersData = computed(() => state.partners.partnersData);
    const stats = computed(() => state.accounts.stats);
    const userPartnerInfo = computed(() => state.partners.userPartnerInfo);
    const dataSource = computed(() => {
      let computedData = [];

      for (let i = 0; i < partnersData.value.length; i++) {
        const result = findData(partnersData.value[i], searchingName.value);
        if (result) {
          computedData.push(result);
        }
      }

      return computedData;
    });

    const refferalLink = ref({
      en: process.env.VUE_APP_API_EN_REFERAL_SHARING_URL,
      ru: process.env.VUE_APP_API_RU_REFERAL_SHARING_URL,
    });
    const expandedRows = ref([]);
    const searchingName = ref('');
    const tableRef = ref();

    const renderFooter = () => {
      return <div style="width:100%;border:1px solid #F1F2F6"></div>;
    };

    const closeTab = (data) => {
      data.onExpand();
    };

    const copyAddress = () => {
      window.navigator.clipboard.writeText(`${refferalLink.value[i18n.locale]}?ref=${userPartnerInfo.value.code}`);
      message.success(i18n.t('messages.linkCopied'));
    };
    const copyCode = () => {
      window.navigator.clipboard.writeText(userPartnerInfo.value.code);
      message.success(i18n.t('messages.codeCopied'));
    };

    const onExpand = (keys) => {
      expandedRows.value = keys;
    };

    function expandAllRows(data) {
      data.map((node) => {
        if (node.children) {
          expandedRows.value.push(node.key);
          expandAllRows(node.children);
        }
      });
    }

    function findData(currentNode, searchValue) {
      if (!searchValue || searchValue.length < 2) {
        return currentNode;
      }
      /*eslint-disable-next-line*/
      let i, result, currentChild;
      if (
        currentNode.name_surname.toString().toLowerCase().includes(searchValue.toString().toLowerCase()) ||
        currentNode.referal_code.toString().toLowerCase().includes(searchValue.toString().toLowerCase()) ||
        currentNode.phone.toString().toLowerCase().includes(searchValue.toString().toLowerCase()) ||
        currentNode.email.toString().toLowerCase().includes(searchValue.toString().toLowerCase())
      ) {
        return currentNode;
      } else if (currentNode.children) {
        for (i = 0; i < currentNode.children.length; i += 1) {
          currentChild = currentNode.children[i];
          result = findData(currentNode.children[i], searchValue);
          if (result !== false) {
            return currentNode;
          }
        }
      }
      return false;
    }

    const expandRows = () => {
      if (searchingName.value.length > 1) {
        expandAllRows(dataSource.value);
      } else {
        expandedRows.value = [];
      }
    };

    const modalChangeActivity = () => {
      isModalActive.value = !isModalActive.value;
    };

    // const changeVisibleLevelInfoModal = () => {
    //   visibleLevelInfoModal.value = !visibleLevelInfoModal.value;
    // };

    const columns = computed(() => [
      {
        title: i18n.t('partners.levels'),
        dataIndex: 'childLevel',
        key: 'childLevel',
      },
      {
        title: i18n.t('partners.registration'),
        dataIndex: 'created_at',
        key: 'created_at',
        slots: { customRender: 'crated_at' },
      },
      //{
      //  title: i18n.t('partners.refferalCode'),
      //  dataIndex: 'referal_code',
      //  key: 'referal_code',
      //  slots: { customRender: 'refferalCode' },
      //},
      // {
      //   title: i18n.t('partners.namesec'),
      //   dataIndex: 'name_surname',
      //   key: 'name_surname',
      //   slots: { customRender: 'namesec' },
      // },
      {
        title: i18n.t('partners.partnersCol'),
        dataIndex: 'partners_count',
        key: 'partners_count',
      },
      {
        title: i18n.t('partners.email'),
        dataIndex: 'email',
        key: 'email',
        slots: { customRender: 'email' },
      },
      // {
      //   title: i18n.t('partners.phone'),
      //   dataIndex: 'phone',
      //   key: 'phone',
      //   slots: { customRender: 'phone' },
      // },

      {
        title: i18n.t('partners.sum_commission'),
        dataIndex: 'sum_commission',
        key: 'sum_commission',
        slots: { customRender: 'sum_commission' },
      },

      {
        title: '',
        width: 0,
      },
    ]);

    onMounted(() => {
      if (partnersData.value.length < 1) {
        dispatch('axiosPartnersGetData');
      }

      if (!stats.value) {
        dispatch('getStats');
      }
    });
    return {
      i18n,
      dataSource,
      isModalActive,
      columns,
      userPartnerInfo,
      stats,
      refferalLink,
      searchingName,
      expandedRows,
      tableRef,
      activeKey,
      visibleLevelInfoModal,
      // changeVisibleLevelInfoModal,
      copyAddress,
      copyCode,
      onExpand,
      expandRows,
      renderFooter,
      modalChangeActivity,
      closeTab,
    };
  },
};

export default ViewPage;
</script>
<style lang="scss">
@for $i from 1 to 9 {
  .ant-table-body .dISvTC table tbody tr.ant-table-row-level-#{$i}:nth-last-child(1) > td {
    border-bottom: 1px solid #9299b8 !important;
    border-radius: 0 !important;
  }
}

.ant-table-row-level-10 {
  display: none;
}
.partners {
  &__referral-card {
    max-width: 360px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    align-items: center;
    margin-left: 20px;
    @media screen and (max-width: 500px) {
      margin-top: 20px;
      max-width: 100%;
      width: 100%;
      margin-left: 0;
    }
    h3 {
      max-width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @media screen and (max-width: 800px) {
        max-width: 200px;
        width: 100%;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 500px) {
      justify-content: center;
    }
  }
  &__copy {
    @media screen and (max-width: 800px) {
      display: none;
      margin-left: 0;
    }
    margin-left: 20px;
  }
}
.searched span {
  display: inline !important;
}
.partners__level {
  h3 {
    color: rgb(255, 204, 2);
    font-weight: 600;
  }
  &.level_common {
    h3 {
      color: #272b41;
    }
  }
}
.stat-block {
  display: flex;
  justify-content: flex-start; //space-around;
  gap: 20px;
  width: 100%;
  margin-top: 20px;

  &__inner {
  }
  &__title {
    font-size: 14px;
    margin: 0;
  }
  &__value {
    font-size: 16px;
    margin: 0;
    font-weight: 600;
    //text-align: center;
  }
}
.points-stat-block {
  display: flex;
  justify-content: flex-start; //space-around;
  //gap: 20px;
  width: 100%;
  margin-top: 20px;

  @media only screen and (max-width: 769px) {
    display: block;
  }
  &__left {
    width: 50%;
    padding: 0 20px 0 0;
    @media only screen and (max-width: 769px) {
      width: 100%;
    }
  }
  &__right {
    width: 50%;
    padding: 0 20px 0 0;
    @media only screen and (max-width: 769px) {
      width: 100%;
    }
  }
  &__inner {
    display: flex;
    justify-content: flex-start;
    padding: 0 0 4px 0;
  }
  &__title {
    font-size: 14px;
    margin-right: auto;
    //margin: 0;
  }
  &__value {
    font-size: 16px;
    //margin: 0;
    font-weight: 600;
    margin-left: auto;
    //text-align: center;
  }

  &-percent {
    font-size: 56px;
    text-align: center;
    font-weight: 600;
  }
}
.promo-block {
  &__inner {
    padding: 0 0 10px 0;
  }
  &__title {
    font-size: 16px;
    font-weight: 500;
  }
  &__value {
    font-size: 14px;
    font-weight: 400;
  }
}
//.partners-card {
//  min-height: 220px;
//}
</style>
