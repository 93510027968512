import Styled from 'vue3-styled-components';

const SalesTargetWrap = Styled.div`
		height:100%;
		padding-bottom:25px;
    .ant-card{
        min-height: 435px;
				height:100%;
    }

		.s-target-list{
			margin-top:20px;
			&~h2{
				font-size:25px;
			}
		}
    .s-target-list__item{
			text-align:center;
				h2{
					line-height:1.4;
					font-size: 22px;
					font-weight: 600;
    @media only screen and (max-width: 450px){
			font-size: 20px;
			line-height:1;
		}
        }
				.btn-icon{
					margin:10px 0 21px;
				}
				.target-topline{
					border-top:1.5px solid rgba(0,0,0,.9);
				}
				.target-reward{
					padding:16px 0;
				}
        .target-revinue{
					color: ${({ theme }) => theme['success-color']};
        }
        p{
            margin-bottom: 0;
            color: ${({ theme }) => theme['gray-color']};
        }
    }
`;

const CarouselStyleWraper = Styled.div`
		.ant-carousel .slick-slider {
			text-align: center;
			background: transparent;
			direction: ltr;
			overflow: hidden;
			height:180px;
		}
		.slick-list, .slick-track, slick-track > div{
			min-height:100%;
		}
		.ant-carousel .slick-dots li{
			width: 15px;
		}
		.ant-carousel .slick-dots li.slick-active{
			width: 25px;
		}
		.ant-radio-group{
			margin-bottom: 16px !important;
		}
		.ant-radio-button-wrapper{
			height: 38px !important;
			line-height: 36px !important;
			font-weight: 600;
			color:black;
		}	
		.slick-slide> div{
			padding 0 10px;
		}
		.ant-carousel .slick-slide h3 {
			color: black;
		}
		.slider {
			&__card {
				padding: 20px;
				border-radius:10px;
				height:180px;
				display:flex;
				justify-content:space-around;
				flex-direction:column;
				align-items:center;
				background:#fff;
			
				h2{
					margin-bottom:0;
					color:#20C997;

				}
				p{
					margin-bottom:0;
				}
				&-title{
					display:flex;
					justify-content:space-between;
					width:100%;
					flex-wrap:wrap;
					@media screen and (max-width:397px){
						button:nth-child(2){
							margin-top:5px;
						}
					}
					
				}
			}
			&__next-level{
				text-align:right;
				padding:5px 10px;
				background-color:rgba(122, 118, 255, 0.2);
				border-radius:5px
				color:#7A76FF !important;
				font-weight: 600;
				font-size: 16px;
			}
			&__users{
				display:flex;
				align-items:center;
				padding:5px 10px;
				background-color:rgba(255, 159, 0, 0.2);
				border-radius:5px
				color:#FF9F00 !important;
				font-weight: 600;
				font-size: 16px;
				span{
					margin-left:5px;
				}
			}
		}
		}
	`;

const SalesGrowthWrap = Styled.div`
    @media only screen and (max-width: 1599px){
        min-height: 435px;
        background-color: #fff;
        border-radius: 10px;
    }
    .growth-chart-wrap{
        margin-top: 58px;
    }
    .growth-list__item{
        h2{
            font-size: 22px;
            font-weight: 600;
            line-height: 1.4;
            margin-bottom: 2px;
        }
        p{
            margin-bottom: 0;
            color: ${({ theme }) => theme['gray-color']};
        }
    }
    canvas{
        margin-bottom: 0 !important;
    }
`;

const RecordViewWrapper = Styled.div`
    .btn-add_new{
        ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 10px;
        a{
            display: flex;
            align-items: center;
            svg,
            img,
            i{
                ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 6px;
            }
        }
    }
    .search-box{
        position: relative;
        box-shadow: 0 5px 5px rgba(#9299B8,.3);
        .search-icon{
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 18px;
            top: 50%;
            transform: translateY(-50%);
            svg,
            img{
                margin-top: 6px;
                min-width: 16px;
                color: #9299B8;
            }
        }
        input{
            border: 0 none;
            height: 40px;
            min-width: 280px;
            padding: ${({ theme }) => (theme.rtl ? '0 45px 0 20px' : '0 20px 0 45px')};
            border-radius: 6px;
            &::placeholder{
                color: #ADB4D2;
            }
            &:focus{
                outline: none;
            }
        }
    }
`;

const RecordFormWrapper = Styled.div`
    .pro-image{
        position: relative;
        margin-bottom: 30px;
        .ant-spin.ant-spin-spinning{
            position: absolute;
            top: 0;
            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
            width: 120px;
            height: 120px;
            background: #ffffff90;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            z-index: 10;
            &:after{
                position: absolute;
                ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: #272B4120;
                content: '';
                z-index: -1;
            }
            .ant-spin-dot {
                position: relative;
                z-index: 10;
            }
        }
        img{
            max-width: 120px;
            min-width: 120px;
            border-radius: 50%;
        }
        .ant-spin{
            height: 120px;
            width: 120px;
            display: flex;
            align-items: center;
        }
        .ant-upload-select{
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 80px;
            bottom: -5px;
            height: 40px;
            width: 40px;
            background: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            z-index: 222;
            span{
                display: inline-flex;
                height: 32px;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 32px;
                background: #5F63F2;
            }
            svg{
                color: #fff;
            }
        }
        .upload-btn{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
        }
        .info{
            ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 20px;
            background: none;
            h1{
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 0;
            }
        }
        .ant-upload-list-item{
            margin-top: 0;
            &:hover{
                .ant-upload-list-item-info{
                    background-color: transparent;
                }
            }
            .ant-upload-list-item-info{
                >span{
                    display: flex;
                    align-items: center;
                    ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 14px;
                    ${({ theme }) => (!theme.rtl ? 'padding-right' : 'padding-left')}: 10px;
                }
                .ant-upload-list-item-card-actions {
                    /* // top: -8px; */
                }
            }
        }
    }
    .record-spin{
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
export { RecordViewWrapper, RecordFormWrapper, SalesTargetWrap, SalesGrowthWrap, CarouselStyleWraper };
